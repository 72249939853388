import React from 'react';
// countup
import CountUp from 'react-countup';
// intersection observer hook
import { useInView } from 'react-intersection-observer';
// motion
import { motion } from 'framer-motion';
// variant
import { fadeIn } from '../variants';

const About = () => {
  const [ref, inView] = useInView({
    threshold: 0.5,
  });
  return (
    <section className='section' id='about' ref={ref}>
      <div className='container mx-auto'>
        <div className='flex flex-col gap-y-10 lg:flex-row lg:items-center lg:gap-x-20 lg:gap-y-0 h-screen'>
          {/* img */}
          <motion.div
            variants={fadeIn('right', 0.3)}
            initial='hidden'
            whileInView={'show'}
            viewport={{ once: false, amount: 0.3 }}
            className='flex-1 bg-about bg-contain bg-no-repeat h-[640px] mix-blend-lighten bg-top'
          ></motion.div>
          {/* text */}
          <motion.div
            variants={fadeIn('left', 0.5)}
            initial='hidden'
            whileInView={'show'}
            viewport={{ once: false, amount: 0.3 }}
            className='flex-1'
          >
            <h2 className='h2 text-accent mb-1'>About me</h2>
            <h3 className='h3 mb-1'>
              I'm a Freelance Software Test Engineer with over 2 years of
              experience.
            </h3>
            <h3 color='red' className='h3 mb-0 text-accent'>
              SKILLS
            </h3>
            <p className='text-gradient'>
            &nbsp;&nbsp;&nbsp;&nbsp; → Testing Tools <br></br>
            </p>
            <p>&nbsp;Selenium WebDriver/Grid, JUnit, TestNG,
              Cucumber, API Restful, <br></br> Rest Assured/ Postman,
              Appium, Apache POI,JMeter(keeps learning)
              </p>
            <p className='text-gradient'>
            &nbsp;&nbsp;&nbsp;&nbsp;→ Languages<br></br>
            </p>
            <p>
            Java, SQL, Gherkin
            </p>
            <p className='text-gradient'>
            &nbsp;&nbsp;&nbsp;&nbsp;→ Build/CI/TM Tools <br></br>
            </p>
            <p>
            JENKINS, GitHub, Maven, Jira, Trello
            </p>
            <p className='text-gradient'>
            &nbsp;&nbsp;&nbsp;&nbsp;→ Databases<br></br>
            </p>  
            <p>
            MySQL (JDBC)
            </p>
            <p className='text-gradient'>
            &nbsp;&nbsp;&nbsp;&nbsp;→ Cloud Technologies <br></br>
            </p>  
            <p>
            AWS (Amazon Web Services)
            </p>
            <p className='text-gradient'>
            &nbsp;&nbsp;&nbsp;&nbsp;→ Frameworks<br></br>
            </p>
            <p className='mb-5'>
            Behavior-Driven, Data-Driven, Hybrid, Karate, Page Object Model (POM)
            </p>
            {/* stats */}
            <div className='flex gap-x-6 lg:gap-x-10 mb-12'>
              <div>
                <div className='text-[40px] font-tertiary text-gradient mb-2'>
                  {inView ? <CountUp start={0} end={3} duration={3} /> : null}
                </div>
                <div className='font-primary text-sm tracking-[2px]'>
                  Years of <br />
                  Experience
                </div>
              </div>
              <div>
                <div className='text-[40px] font-tertiary text-gradient mb-2'>
                  {inView ? <CountUp start={0} end={15} duration={3} /> : null}
                  +
                </div>
                <div className='font-primary text-sm tracking-[2px]'>
                  Projects <br />
                  Completed
                </div>
              </div>
              <div>
                <div className='text-[40px] font-tertiary text-gradient mb-2'>
                  {inView ? <CountUp start={0} end={12} duration={3} /> : null}
                  k+
                </div>
                <div className='font-primary text-sm tracking-[2px]'>
                  Satisfied <br />
                  Clients
                </div>
              </div>
            </div>
            <div className='flex gap-x-8 items-center'>
            <a href="mailto:tekinayecerkezoglu05@gmail.com"><button onclick="window.location.href = 'mailto:tekinaycerkezoglu05@gmail.com';" className='btn btn-lg'>Contact me</button></a>
              <a href='https://github.com/Teykup' className='text-gradient btn-link'>
                My Portfolio
              </a>
            </div>
          </motion.div>
        </div>
      </div>
    </section>
  );
};

export default About;
