import React from 'react';
// icon
import { BsArrowUpRight } from 'react-icons/bs';
// motion
import { motion } from 'framer-motion';
// variants
import { fadeIn } from '../variants';


// services data
const services = [
  {
    name: 'Running Selenium Tests with JUnit in LambdaTest',
    description:
      'Learn how to use the JUnit framework to configure and run your Java automation test scripts on the LambdaTest platform',
    link: 'Learn more',
  },
  {
    name: 'Web and API Test Projesi without RestAssured',
    description:
      'The aim of the project is to create a testing framework for API calls and functionality of a website.',
    link: 'Learn more',
  },
  {
    name: 'MUN events (Language related)',
    description:
      'Contains my MUN certificates and pictures. Check out my fun times!',
    link2: 'Learn more',
  },
  {
    name: 'Product Branding',
    description:
      'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Maiores, quia quo expedita accusamus illum ducimus.',
    link: 'Learn more',
  },
];

const Services = () => {
  return (
    <section className='section mt-40' id='services'>
      <div className='container mx-auto mt-20'>
        <div className='flex flex-col lg:flex-row mt-20'>
          {/* text & image */}
          <motion.div
            variants={fadeIn('right', 0.3)}
            initial='hidden'
            whileInView={'show'}
            viewport={{ once: false, amount: 0.3 }}
            className='flex-1 lg:bg-services lg:bg-bottom bg-no-repeat mix-blend-lighten mb-1 lg:mb-0'
          >
            <h2 className='h2 text-accent mb-6'>What I Do.</h2>
            <h3 className='h3 max-w-[455px] mb-16'>
            I'm a Software Test Engineer with over 2 years of experience.I participated in many projects and events to improve myself. Some of those...
            </h3>
            <a href="https://github.com/Teykup"><button className='btn btn-sm'>See my work</button></a>
          </motion.div>
          {/* services */}
          <motion.div
            variants={fadeIn('left', 0.5)}
            initial='hidden'
            whileInView={'show'}
            viewport={{ once: false, amount: 0.3 }}
            className='flex-1'
          >
            {/* service list */}
            <div>
              {services.map((service, index) => {
                // destructure service
                const { name, description, link,link2 } = service;
                return (
                  <div
                    className='border-b border-white/20 h-[146px] mb-[38px] flex'
                    key={index}
                  >
                    <div className='max-w-[476px]'>
                      <h4 className='text-[20px] tracking-wider font-primary font-semibold mb-6'>
                        {name}
                      </h4>
                      <p className='font-secondary leading-tight'>
                        {description}
                      </p>
                    </div>
                    <div className='flex flex-col flex-1 items-end'>
                      <a
                        href='https://github.com/Teykup'
                        className='btn w-9 h-9 mb-[42px] flex justify-center items-center'
                      >
                        <BsArrowUpRight />
                      </a>
                      <a href='https://github.com/Teykup' className='text-gradient text-sm'>
                        {link}
                      </a>
                      <a href='https://google.com' className='text-gradient text-sm'>
                        {link2}
                      </a>
                      
                    </div>
                  </div>
                );
              }
              
              )}
            </div>
            
          </motion.div>
        </div>
      </div>
    </section>
  );
};

export default Services;
