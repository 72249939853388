import React, { useRef, useState } from 'react';
import { motion } from 'framer-motion';
import { fadeIn } from '../variants';
import emailjs from '@emailjs/browser';

const initialState = {
  user_name: '', // Changed 'name' to 'user_name' to match input field names
  user_email: '', // Changed 'email' to 'user_email' to match input field names
  message: '',
};

const Contact = () => {
  const form = useRef();
  const [statusMessage, setStatusMessage] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  };

  const [{ user_name, user_email, message }, setState] = useState(initialState);

  const clearState = () => setState({ ...initialState });

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm('service_18xns2p', 'template_t53idce', form.current, 'Rl9SB2tKBZBdCZM7X')
      .then(
        (result) => {
          console.log(result.text, result.status);
          setStatusMessage('Email sent successfully ✓'); // Fixed typo in success message
          setTimeout(() => {
            setStatusMessage('');
            clearState();
          }, 3000); // Display success message for 3 seconds before resetting
        },
        (error) => {
          console.log(error.text);
          setStatusMessage(`${error.text} happened`);
        }
      );
  };

  return (
    <section className='py-16 lg:section' id='contact'>
      <div className='container mx-auto'>
        <div className='flex flex-col lg:flex-row'>
          {/* text */}
          <motion.div
            variants={fadeIn('right', 0.3)}
            initial='hidden'
            whileInView={'show'}
            viewport={{ once: false, amount: 0.3 }}
            className='flex-1 flex justify-start items-center'
          >
            <div>
              <h4 className='text-xl uppercase text-accent font-medium mb-2 tracking-wide'>
                Get in touch
              </h4>
              <h2 className='text-[45px] lg:text-[90px] leading-none mb-12'>
                Let's work <br /> together!
              </h2>
            </div>
          </motion.div>
          {/* form */}
          <motion.form
            ref={form}
            onSubmit={sendEmail}
            variants={fadeIn('left', 0.3)}
            initial='hidden'
            whileInView={'show'}
            viewport={{ once: false, amount: 0.3 }}
            className='flex-1 border rounded-2xl flex flex-col gap-y-6 pb-24 p-6 items-start'
          >
            <input
              className='bg-transparent border-b py-3 outline-none w-full placeholder:text-white focus:border-accent transition-all'
              type='text'
              name='user_name'
              placeholder='Your name'
              value={user_name}
              onChange={handleChange}
            />
            <input
              className='bg-transparent border-b py-3 outline-none w-full placeholder:text-white focus:border-accent transition-all'
              type='text'
              name='user_email'
              placeholder='Your email'
              value={user_email}
              onChange={handleChange}
            />
            <textarea
              className='bg-transparent border-b py-12 outline-none w-full placeholder:text-white focus:border-accent transition-all resize-none mb-12'
              placeholder='Your message'
              name='message'
              value={message}
              onChange={handleChange}
            ></textarea>
            <button type='submit' value='Send' className='btn btn-lg'>
              Send message
            </button>
          </motion.form>

          {/* Display success message here */}
          {statusMessage && (
            <motion.p
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              transition={{ duration: 0.7 }}
              style={{ marginLeft: '28%', marginTop: '14.2%' }}
              className='success-message'
            >
              {statusMessage}
            </motion.p>
          )}
        </div>
      </div>
    </section>
  );
};

export default Contact;
